import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Content } from "rbx"

const slugify = require("@sindresorhus/slugify")

const CreationsTOC = ({ author, creations }) => (
  <ul>
    {creations.map((node, index) => (
      <li key={"creation" + index}>
        <Link to={`/creations/${slugify(author)}/${slugify(node.title)}`}>
          {node.title}
        </Link>
      </li>
    ))}
  </ul>
)

const TOC = ({ tree }) => (
  <ul>
    {tree.map((node, index) => (
      <li key={"author" + index}>
        <strong>{node.author}</strong>
        <CreationsTOC author={node.author} creations={node.creations} />
      </li>
    ))}
  </ul>
)

const CreationsList = () => {
  const data = useStaticQuery(graphql`
    query {
      allCreations {
        nodes {
          title
          author
          body
        }
      }
    }
  `)

  let creations = {}
  data.allCreations.nodes.forEach(creation => {
    const { author, ...creation_wo_author } = creation
    if (!Object.keys(creations).includes(creation.author)) {
      creations[creation.author] = []
    }
    creations[creation.author].push(creation_wo_author)
  })

  let creationsTree = []
  for (let [key, value] of Object.entries(creations)) {
    creationsTree.push({
      author: key,
      creations: value,
    })
  }

  return (
    <Content size="medium">
      <TOC tree={creationsTree} />
    </Content>
  )
}

export default CreationsList
