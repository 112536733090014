import React from "react"
import { Content, Title } from "rbx"

import CreationsList from "src/components/creations/list"
import Layout from "src/layouts/inner"
import SEO from "src/components/seo"

const CreationsPage = () => (
  <Layout>
    <SEO title="Стихи о войне" path="/creations" />
    <Title>Стихи о войне</Title>
    <CreationsList />
    <Content size="small">
      <p>
        Стихи отобраны нами из публикаций на сайтах —{" "}
        <a href="https://rustih.ru" target="_blank" rel="noopener noreferrer">
          https://rustih.ru
        </a>
        ,{" "}
        <a
          href="https://www.culture.ru"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.culture.ru
        </a>
        ,{" "}
        <a
          href="https://www.stihi.ru"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.stihi.ru
        </a>
        ,{" "}
        <a href="https://rupoem.ru" target="_blank" rel="noopener noreferrer">
          https://rupoem.ru
        </a>
        ,{" "}
        <a
          href="https://otmetim.info"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://otmetim.info
        </a>
        ,{" "}
        <a
          href="https://pishi-stihi.ru"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://pishi-stihi.ru
        </a>
        ,{" "}
        <a
          href="https://www.pravmir.ru"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.pravmir.ru
        </a>
        ,{" "}
        <a href="https://proza.ru" target="_blank" rel="noopener noreferrer">
          https://proza.ru
        </a>
        ,{" "}
        <a href="https://prostih.ru" target="_blank" rel="noopener noreferrer">
          https://prostih.ru
        </a>
        ,{" "}
        <a
          href="http://www.vestnik.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.vestnik.com
        </a>
        ,{" "}
        <a href="https://rupoets.ru" target="_blank" rel="noopener noreferrer">
          https://rupoets.ru
        </a>
      </p>
      <p>
        Если вы обнаружили ошибку, пожалуйста,{" "}
        <a href="mailto:mistake@golospamyati.ru?subject=Ошибка в стихотворении">
          сообщите нам{" "}
        </a>
        .
      </p>
    </Content>
  </Layout>
)

export default CreationsPage
